

<div class="row" style="margin-top: 10px">
  <div class="col-md-3"></div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h3>Type your Instance URL</h3>
        <br>
          <div class="text-center">
          <input type="text" style="text-align: right; width: 100px" placeholder="tenant">.guild.page
          </div>
        <br>
        <button class="btn btn-primary btn-block">Login</button>
      </div>
    </div>
  </div>
</div>
