<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top" style="border-radius: 0px 0px 10px 10px;">
    <a class="navbar-brand" href="#">Guild.page</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/">Home</a>
        </li>
        <!--
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        -->
      </ul>
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <a class="nav-link" routerLink="/login">Login</a>
        </li>
      </ul>
    </div>
  </nav>

  <router-outlet></router-outlet>
  <hr>
  <footer>
    &copy; 2021, <a href="https://sys243.com">Sys243 Solutions</a>
  </footer>
</div>
