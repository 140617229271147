<div class="row" style="margin-top: 15px">
  <div class="col-md-4 d-md-block d-sm-none">
    <div class="card border border-primary" style="margin-top: 17px">
      <div class="card-body text-center">
        Chat CAPTCHAs for Entry Control
      </div>
    </div>
    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Chat Federation
      </div>
    </div>
    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Automated Rule Enforcement
      </div>
    </div>
    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Strike / Points Based Infraction System
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4">
    <div class="card d-sm-none d-md-block border border-primary" style="margin-bottom: 10px">
        <div class="card-body text-center">
          Unified Rulesets
        </div>
    </div>

    <div class="card d-md-block bg-primary border border-primary text-light" style="height: 200px">
      <div class="card-body text-center" style="margin-top: 9%">
        <h2>Guild.page</h2>
        <p>Highly Customizable Moderation Platform for Telegram Chats</p>
      </div>
    </div>

    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        REST API for Custom Bots
      </div>
    </div>
  </div>


  <div class="col-md-4 d-sm-none d-md-block">
    <div class="card border border-primary" style="margin-top: 17px">
      <div class="card-body">
        Automated Staff and Admin Management
      </div>
    </div>

    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Telegram Login Integration
      </div>
    </div>

    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Open Source Bot
      </div>
    </div>
    <div class="card border border-primary" style="margin-top: 15px">
      <div class="card-body text-center">
        Per-Group Addendum Rulesets
      </div>
    </div>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-3"></div>
  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Closed Beta</h4>
      </div>
      <div class="card-body">
        We are currently in closed beta. If you would like to participate, e-mail hello@guild.page
      </div>
    </div>
  </div>
  <div class="col-3"></div>
</div>
